<template>
  <div>

    <v-row style="padding: 0px !important;">
      <v-col cols="12" md="12" sm="12">
        <h2 v-if="$vuetify.breakpoint.mobile"
            style="font-family: 'LexiaBold','Khmer Os Muol Light' !important;padding-top: 0px;font-size: 34px !important;font-weight: normal !important;">
          {{
            $t('dataOfAssociationOfTheElderly')
          }}</h2>
        <h1 v-if="!$vuetify.breakpoint.mobile"
            style="font-family: 'LexiaBold','Khmer OS Muol Light' !important;padding-top: 0px;font-size: 34px !important;font-weight: normal !important;">
          {{
            $t('dataOfAssociationOfTheElderly')
          }}</h1>
      </v-col>
      <v-col cols="12" md="12" sm="12" style="padding: 0px !important;">
        <img src="@/assets/banner.png" style="max-width: 100%;"/><br>
      </v-col>

      <v-col cols="12" md="12" sm="12" style="justify-content: center" v-if="$vuetify.breakpoint.mobile">
        <v-img src="@/assets/map.png" v-if="$vuetify.breakpoint.mobile" style="max-width: 600px"></v-img>
      </v-col>

      <v-col cols="12" md="12" sm="12" style="justify-content: center">
        <h1 style="font-family: LexiaBold,'Khmer Os Muol Light' !important;padding-top: 20px;font-weight: normal !important;">
          {{
            $t('findThem')
          }}</h1>
      </v-col>

      <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" md="3" sm="3"></v-col>
      <v-col cols="12" md="6" sm="6">
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            filled
            :style="$vuetify.breakpoint.mobile ? 'font-size: 16px !important;' : 'font-size: 25px !important;'"
            hide-details
            rounded
            clear-icon="mdi-close-circle"
            clearable
            @keyup.enter="handleSearch"
            :placeholder="$t('typingNameToSearch')"
            type="text"
            @click:append="handleSearch"

        ></v-text-field>
      </v-col>

      <v-col cols="12" md="12" sm="12" v-if="search!==''">
        <h3 style="color: green;font-weight: normal !important;" v-if="result.length>0">{{ $t('checkDataBelow') }}</h3>
        <h3 style="color: red" v-if="result.length===0 && search!=='' && isSearch===true">{{ $t('noData') }}</h3>
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" md="3" sm="3"></v-col>
      <v-col cols="12">
        <h1 style="font-family: LexiaBold,'Khmer Os Muol Light' !important;font-weight: normal !important;">
          {{ $t('orChooseByLocationBelow') }}</h1>
      </v-col>

      <v-col cols="12" md="6" sm="6" style="padding: 30px">
        <v-row :style="$vuetify.breakpoint.mobile ? 'padding-top:20px' : ''">
          <v-col cols="12" sm="12" md="12" class="d-flex"
                 :style="$vuetify.breakpoint.mobile ? 'justify-content: center;padding-top:20px' : 'justify-content: center'">
            <v-autocomplete v-model="params.provinceId"
                            :items="provinceOptionList"
                            outlined
                            :dense="dense"
                            rounded
                            clearable
                            style="max-width: 500px"
                            hide-details
                            hide-no-data
                            item-text="label"
                            item-value="value"
                            :label="$t('chooseProvince')"
                            search-input
                            required
            >

              <template slot="item" slot-scope="{item}">
                {{ item.label }}
              </template>

            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="d-flex"
                 v-if="params.provinceId!==''  && params.provinceId!==null && (params.districtId==='' ||  params.districtId===null)"
                 style="justify-content: center;padding:0px">
            <h3 style="color: green;font-weight: normal !important;" v-if="result.length>0">{{
                $t('checkDataBelow')
              }}</h3>
            <h3 style="color: red;font-weight: normal !important;" v-if="result.length===0 && params.provinceId!==''">
              {{ $t('noData') }}</h3>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="d-flex"
                 :style="$vuetify.breakpoint.mobile ? 'justify-content: center;padding-top:20px' : 'justify-content: center'">
            <v-autocomplete v-model="params.districtId"
                            :items="districtOptionList"
                            outlined
                            :dense="dense"
                            rounded
                            clearable
                            hide-details
                            hide-no-data
                            style="max-width: 500px"
                            item-text="label"
                            item-value="value"
                            :label="$t('chooseDistrict')"
                            search-input
                            required
            >

              <template slot="item" slot-scope="{item}">
                {{ item.label }}
              </template>

            </v-autocomplete>

          </v-col>
          <v-col cols="12" sm="12" md="12" class="d-flex"
                 v-if="params.districtId!==''  && params.districtId!==null && (params.communeId==='' ||  params.communeId===null)"
                 style="justify-content: center;padding:0px">
            <h3 style="color: green;font-weight: normal !important;" v-if="result.length>0">{{
                $t('checkDataBelow')
              }}</h3>
            <h3 style="color: red;font-weight: normal !important;" v-if="result.length===0 && params.districtId!==''">
              {{ $t('noData') }}</h3>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="d-flex"
                 :style="$vuetify.breakpoint.mobile ? 'justify-content: center;padding-top:20px' : 'justify-content: center'">
            <v-autocomplete v-model="params.communeId"
                            :items="communeOptionList"
                            outlined
                            :dense="dense"
                            rounded
                            clearable
                            hide-details
                            hide-no-data
                            style="max-width: 500px"
                            item-text="label"
                            item-value="value"
                            :label="$t('chooseCommune')"
                            search-input
                            required
            >

              <template slot="item" slot-scope="{item}">
                {{ item.label }}
              </template>

            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="d-flex"
                 v-if="params.communeId!=='' && params.communeId!==null && (params.villageId===''  ||  params.villageId===null)"
                 style="justify-content: center;padding:0px">
            <h3 style="color: green;font-weight: normal !important;" v-if="result.length>0">{{
                $t('checkDataBelow')
              }}</h3>
            <h3 style="color: red;font-weight: normal !important;" v-if="result.length===0 && params.communeId!==''">
              {{ $t('noData') }}</h3>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="d-flex"
                 :style="$vuetify.breakpoint.mobile ? 'justify-content: center;padding-top:20px' : 'justify-content: center'">
            <v-autocomplete v-model="params.villageId"
                            :items="villageOptionList"
                            outlined
                            :dense="dense"
                            rounded
                            clearable
                            style="max-width: 500px"
                            hide-details
                            hide-no-data
                            item-text="label"
                            item-value="value"
                            :label="$t('chooseVillage')"
                            search-input
                            required
            >

              <template slot="item" slot-scope="{item}">
                {{ item.label }}
              </template>

            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="d-flex" v-if="params.villageId!=='' && params.villageId!==null"
                 style="justify-content: center;padding:0px">
            <h3 style="color: green;font-weight: normal !important;" v-if="result.length>0">{{
                $t('checkDataBelow')
              }}</h3>
            <h3 style="color: red;font-weight: normal !important;" v-if="result.length===0 && params.villageId!==''">
              {{ $t('noData') }}</h3>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6" sm="6" style="justify-content: center" v-if="!$vuetify.breakpoint.mobile">
        <v-img src="@/assets/map.png" v-if="!$vuetify.breakpoint.mobile" style="max-width: 600px"></v-img>
      </v-col>
      <v-col cols="12" md="12" sm="12" :style="$vuetify.breakpoint.mobile ? '' : 'padding-left: 100px'">
        <v-container>
          <h1 style="font-family: LexiaBold,'Khmer Os Muol Light' !important;text-align: left;font-weight: normal !important;"
              id="resultsearch">
            {{ $t("findResult") }}</h1>
          <p :style="$vuetify.breakpoint.mobile ? 'text-align: left;cursor: pointer;' : 'text-align: left;cursor: pointer;font-size: 24px'"
             v-for="doc in result" :key="doc._id"
             @click="gotoAssociation(doc._id)">
            <v-btn color="primary" outlined class="table-action-button mr-2" text icon
                   @click="gotoAssociation(doc._id)">
              <!--            <v-icon>mdi-hand-pointing-up</v-icon>-->
              <v-img src="@/assets/hand.png"
                     :style="$vuetify.breakpoint.mobile ? 'max-width: 35px' : 'max-width: 40px' "></v-img>
            </v-btn>
            <span v-if="$i18n.locale=='en'">
              <b>{{ doc.code }} : {{ doc.latinName || doc.name }} </b>,
              {{ doc.villageDoc && doc.villageDoc.latinName || doc.villageDoc.name || "" }} Village,
              {{ doc.communeDoc && doc.communeDoc.latinName || doc.communeDoc.name || "" }} Commune,
              {{ doc.districtDoc && doc.districtDoc.latinName || doc.districtDoc.name || "" }} District,
              {{ doc.provinceDoc && doc.provinceDoc.latinName || doc.provinceDoc.name || "" }} Province
            </span>
            <span v-if="$i18n.locale!=='en'">
              <b>{{ doc.code }} : {{ doc.name }} </b>
              ភូមិ {{ doc.villageDoc && doc.villageDoc.name || "" }}
              ឃុំ/សង្កាត់ {{ doc.communeDoc && doc.communeDoc.name || "" }} ស្រុក/ខ័ណ្ឌ
              {{ doc.districtDoc && doc.districtDoc.name || "" }}
              រាជធានី/ខេត្ត {{ doc.provinceDoc && doc.provinceDoc.name || "" }}
            </span>

          </p>
          <h1 v-if="result.length===0">
            {{ $t('noData') }}
          </h1>
        </v-container>
      </v-col>
      <div id="bottom"></div>


      <v-col cols="12" md="12" sm="12" v-show="result.length==0">
        <v-container>
          <hr style="margin-bottom: 20px"/>
          <v-row>
            <v-col cols="12" md="3" sm="3" lg="3" class="mgTop">
              <v-card style="padding: 12px !important;" elevation="9"
              >
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-card
                      :img="require('../assets/home.jpg')"
                      style="top:-30px !important;max-height: 90px;background-color: #2b469d;padding: 28px !important;"
                      dark raised>
                    <!--                    <v-icon style="font-size: 32px !important;">mdi-home-variant
                                        </v-icon>-->
                  </v-card>
                  <div>
                    {{ $t("totalAssociation") }} <br>
                    <span style="font-size: 28px; font-weight: bold;"> {{ FormatNumber(totalAssociation || 0) }}</span>

                  </div>
                </div>
                <!--                <hr class="v-divider theme&#45;&#45;light">-->

                <!--                <span class="caption grey&#45;&#45;text font-weight-light"><v-icon
                                    style="font-size: 16px;">mdi-home-variant</v-icon> Total Association</span>
                                <span style="float: right">

                                                </span>-->
              </v-card>
            </v-col>
            <v-col cols="12" md="9" sm="9" lg="9" style="margin:0px !important;padding: 0px !important;">
              <v-row>
                <v-col cols="12" md="3" sm="3" lg="3" class="mgTop">
                  <v-card style="padding: 12px !important;" elevation="9"
                  >
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <v-card
                          :img="require('../assets/women.jpg')"
                          style="top:-30px !important;max-height: 90px;padding: 28px !important;"
                          dark raised>
                        <!--                    <v-icon style="font-size: 32px !important;">mdi-human-female
                                            </v-icon>-->
                      </v-card>
                      <div>
                        {{ $t("memberF") }}<br>
                        <span style="font-size: 28px; font-weight: bold;"> {{ FormatNumber(totalFeMale || 0) }}</span>

                      </div>
                    </div>
                    <!--                <hr class="v-divider theme&#45;&#45;light">-->

                    <!--                <span class="caption grey&#45;&#45;text font-weight-light"><v-icon
                                        style="font-size: 16px;">mdi-human-female</v-icon> Member</span>
                                    <span style="float: right">

                                                    </span>-->
                  </v-card>
                </v-col>

                <v-col cols="12" md="3" sm="3" lg="3" class="mgTop">
                  <v-card style="padding: 12px !important;" elevation="9"
                  >
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <v-card
                          :img="require('../assets/men.jpg')"
                          style="top:-30px !important;max-height: 90px;background-color: blue;padding: 28px !important;"
                          dark raised>
                        <!--                    <v-icon style="font-size: 32px !important;">mdi-human-male-->
                        <!--                    </v-icon>-->
                      </v-card>
                      <div>
                        {{ $t("memberM") }}<br>
                        <span style="font-size: 28px; font-weight: bold;"> {{ FormatNumber(totalMale || 0) }}</span>

                      </div>
                    </div>
                    <!--                <hr class="v-divider theme&#45;&#45;light">-->

                    <!--                <span class="caption grey&#45;&#45;text font-weight-light"><v-icon
                                        style="font-size: 16px;">mdi-human-male</v-icon> Member</span>
                                    <span style="float: right">

                                                    </span>-->
                  </v-card>
                </v-col>
                <v-col cols="12" md="3" sm="3" lg="3" class="mgTop">
                  <v-card style="padding: 12px !important;" elevation="9"
                  >
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <v-card
                          :img="require('../assets/fedis.jpg')"
                          style="top:-30px !important;max-height: 90px;padding: 28px !important;"
                          dark raised>
                        <!--                    <v-icon style="font-size: 32px !important;">mdi-human-female
                                            </v-icon>-->
                      </v-card>
                      <div>
                        {{ $t("pwdF") }}<br>
                        <span style="font-size: 28px; font-weight: bold;"> {{
                            FormatNumber(femaleDisability || 0)
                          }}</span>

                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3" sm="3" lg="3" class="mgTop">
                  <v-card style="padding: 12px !important;" elevation="9"
                  >
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <v-card
                          :img="require('../assets/maledis.jpg')"
                          style="top:-30px !important;max-height: 90px;background-color: blue;padding: 28px !important;"
                          dark raised>
                        <!--                    <v-icon style="font-size: 32px !important;">mdi-human-male-->
                        <!--                    </v-icon>-->
                      </v-card>
                      <div>
                        {{ $t("pwdM") }}<br>
                        <span style="font-size: 28px; font-weight: bold;"> {{
                            FormatNumber(maleDisability || 0)
                          }}</span>

                      </div>
                    </div>
                  </v-card>
                </v-col>

              </v-row>
            </v-col>


          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" md="12" sm="12" v-show="result.length==0">
        <v-container>
          <h1> {{ $t("fiveProvincesWithHighNumberOfTheOPA") }}</h1>
          <Bar :chart-options="chartOptions" :chart-data="chartData" :plugins="plugins"/>

          <!--        <Bar :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId" :dataset-id-key="datasetIdKey"
                       :css-classes="cssClasses" :styles="styles" :width="width" :height="height"/>-->
        </v-container>
      </v-col>
      <v-col cols="12" md="12" sm="12" v-show="result.length==0">
        <v-container>
          <h1>{{ $t("threeCommonFunctionOfTheOPA") }}</h1>
          <Bar :chart-options="chartOptionsService" :chart-data="chartDataService"/>
        </v-container>
      </v-col>
      <v-col cols="12" md="12" sm="12" v-show="result.length==0">
        <v-container>
          <h1>{{ $t("functionAndNumberOfTheOPA") }}</h1>
          <!--          <v-row>
                      <v-col cols="12" md="6" sm="6" v-for="(chatDoc,ind) in chartDataActionList" :key="ind"
                             style="padding-bottom: 50px">
                        <h2>{{ chatDoc.serviceName }}</h2>
                        <Doughnut
                            :chart-options="chartOptionsAction"
                            :chart-data="chatDoc"

                            :width="width"
                            :height="height"
                        />
                      </v-col>
                    </v-row>-->
          <v-row>
            <v-col cols="12" :md="$vuetify.breakpoint.mobile ? '12' : '6'" sm="12" style="padding: 0px !important;"
                   class="d-flex flex-row justify-center" v-for="(chatDoc,ind) in chartDataActionList" :key="ind">
              <ul style="text-align: center;color: black;" class="custom-module1">
                <li style="color: black">

                  <v-list-item style="padding: 0px !important;">
                    <v-list-item-icon style="margin: 0px !important;">
                      <img style="width: 100px;height: 100px;"
                           :src="chatDoc.url"/>
                    </v-list-item-icon>

                    <v-list-item-content style="justify-content: center;font-size: 21px">
                      {{
                        chatDoc.serviceName
                      }}<!--<br>{{ chatDoc.serviceNameEn }}-->
                    </v-list-item-content>

                    <v-list-item-action style="margin: 0px !important;">
                      <h1>{{ FormatNumber(chatDoc.total || 0) }}</h1>
                    </v-list-item-action>
                  </v-list-item>

                  <!--                      <div style="float: left">
                                          <img style="width: 100px;height: 100px;"
                                               :src="getServiceURLById(sDoc.serviceId)"/>
                                        </div>

                                        <div style="float: right">
                                          <img style="width: 60px;height: 30px;transform: rotate(270deg)" v-if="sDoc.value==='មិនទាន់មាន'"
                                               src="@/assets/1.png"/>
                                          <img style="width: 60px;height: 30px;transform: rotate(270deg)"
                                               v-if="sDoc.value==='ដំណើរការមិនសូវល្អ'" src="@/assets/2.png"/>
                                          <img style="width: 60px;height: 30px;transform: rotate(270deg)"
                                               v-if="sDoc.value==='ដំណើរការល្អ'" src="@/assets/3.png"/>
                                        </div>
                                        <span style="font-family: Khmer OS Muol Light;width: 350px">{{
                                            getServiceById(sDoc.serviceId)
                                          }}<br>{{ getServiceEnById(sDoc.serviceId) }}
                                        </span>-->
                </li>
                <br>

              </ul>

            </v-col>
          </v-row>

        </v-container>
      </v-col>
      <v-col cols="12" md="6" sm="6" style="padding: 50px" v-show="result.length==0">

        <h1 style="font-size: 21px !important;">{{ $t("administratedBy") }}</h1>
        <div v-for="(n,ind) in prepareByList" :key="ind">
          <v-img
              class="white--text align-end"
              contain
              :src="n.url"
          >
          </v-img>

        </div>
        <!--        <v-row style="display: ruby-text">
                  <v-col v-for="(n,ind) in prepareByList"
                         :key="ind"
                         class="d-flex child-flex">

                    <v-img
                        :src="n.url"
                        :lazy-src="n.url"
                        contain
                        style="background-position: top center !important;"

                        aspect-ratio="1"
                        class="grey lighten-2"

                    >
                      <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                          <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>-->
      </v-col>

      <v-col cols="12" md="6" sm="6" style="padding: 50px" v-show="result.length==0">
        <h1 style="font-size: 21px !important;">{{ $t("supportedBy") }}</h1>
        <div v-for="(n,ind) in supportByList" :key="ind">
          <v-img
              class="white--text align-end"
              contain
              :src="n.url"
          >
          </v-img>

        </div>
        <!--        <v-row style="display: ruby-text">
                  <v-col cols="12" sm="12" md="12" v-for="(n,ind) in supportByList"
                         :key="ind"
                         class="d-flex child-flex">
                    <v-img
                        :src="n.url"
                        :lazy-src="n.url"
                        background-position="top center !important"
                        background-size="contain !important"
                        aspect-ratio="1"
                        class="grey lighten-2"

                    >
                      <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                          <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                  </v-col>
                </v-row>-->
      </v-col>
    </v-row>
  </div>
</template>

<script>

import MyMixin from "@/mixins/mixin"
import numeral from "numeral"

import axios from "axios";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import {Constants} from "../libs/constant";
import {Bar} from 'vue-chartjs/legacy';
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, ChartJSPluginDatalabels)
const backgroundColor = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)',
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)'
];
const borderColor = [
  'rgba(255,99,132,1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(255,99,132,1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)'
];
export default {
  name: "Search",
  mixins: [MyMixin],
  components: {Bar},
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {

    return {
      chartDataActionList: [],
      chartDataAction: {
        labels: [],
        datasets: [
          {

            backgroundColor: backgroundColor,
            pointBorderColor: borderColor,
            data: []
          }
        ]
      },
      chartOptionsAction: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: false
          },
          datalabels: {
            color: "black",
            font: {
              weight: "bold",
              size: "30px",
            }
          },
        }
      },
      chartData: {
        labels: [],
        datasets: [{
          label: 'ទិន្នន័យតំណាងចំនួនសមាគមន៍',
          borderWidth: 1,

          backgroundColor: 'rgba(237,22,79,255)',
          pointBorderColor: borderColor,
          borderColor: "black",
          data: []
        }]
      },
      chartOptions: {
        responsive: true,
        indexAxis: 'y',
        maintainAspectRatio: false,
        scale: {
          y: {
            /*title: {
              font: {
                size: 20,
                weight: "bold"
              },
              color: 'black'
            },
*/
            ticks: {
              beginAtZero: true,
              font: {
                size: 20,
              },
            }
          }
        },
        plugins: {
          legend: {
            position: 'top',
            display: false
          },
          datalabels: {
            anchor: 'end',
            align: 'left',
            color: "white",
            font: {
              weight: "bold",
              size: "25px",
            },
          },
          title: {
            display: false,
            text: '១០ ខេត្តដែលបង្កើតសមាគមច្រើនជាងគេ'
          }
        }
      },
      chartDataService:
          {
            labels: [],
            datasets:
                [{
                  label: 'ទិន្នន័យតំណាងចំនួនសកម្មភាព',
                  borderWidth: 1,

                  backgroundColor: 'rgba(237,22,79,255)',
                  pointBorderColor: borderColor,
                  borderColor: "black",
                  data: []
                }]
          }
      ,
      chartOptionsService: {
        responsive: true,
        indexAxis:
            'y',
        maintainAspectRatio:
            false,
        scale: {
          y: {
            ticks: {
              beginAtZero: true,
              font: {
                size: 20,
              },
            }
          }
        },
        plugins:
            {
              legend: {
                position: 'top',
                display: false
              },

              datalabels: {
                anchor: 'end',
                align:
                    'left',
                color:
                    "white",
                font:
                    {
                      weight: "bold",
                      size:
                          "25px",
                    }
              }
              ,
              title: {
                display: false,
                text:
                    'សកម្មភាពដែលមានដំណើរការល្អធម្មតា'
              }
            }
      }
      ,

      index: null,
      model:
          null,
      search:
          "",
      intro:
          "",
      isSearch:
          false,
      dense:
          false,
      provinceOptionList:
          [],
      districtOptionList:
          [],
      communeOptionList:
          [],
      villageOptionList:
          [],
      prepareByList:
          [],
      supportByList:
          [],
      result:
          [],
      totalAssociation:
          0,
      totalMale:
          0,
      totalFeMale:
          0,
      maleDisability: 0,
      femaleDisability: 0,
      params:
          {
            provinceId: "",
            districtId:
                "",
            communeId:
                "",
            villageId:
                "",
          }
    };
  }
  ,
  mounted() {
    this.onResize();
  }
  ,
  watch: {
    /* videoLink(val) {
       let vm = this;
       vm.videoEmbedCode = "";
       if (val && val.split("youtu.be").length === 2) {
         vm.videoEmbedCode = val.replace("youtu.be", "youtube.com/embed");
       } else if (val && val.split("/watch?v=").length > 0) {
         vm.videoEmbedCode =
             "https://www.youtube.com/embed/" +
             val.split("/watch?v=")[1].split("&list=")[0].split("&t=")[0];
       }
     },*/
    "$i18n.locale"() {
      let vm = this;
      vm.fetchProvince();
      vm.fetchHomePageDashBoard();
    },
    "params.provinceId"(val) {
      this.fetchDistrict(val);
      this.fetchAssociationByGeoOrName();
    }
    ,
    "params.districtId"(val) {
      this.fetchCommune(val);
      this.fetchAssociationByGeoOrName();
    }
    ,
    "params.communeId"(val) {
      this.fetchVillage(val);
      this.fetchAssociationByGeoOrName();
    }
    ,
    "params.villageId"() {
      this.fetchAssociationByGeoOrName();
      setTimeout(function () {
        // window.scrollTo(0, document.body.scrollHeight);
        document.getElementById("resultsearch").scrollIntoView({behavior: 'smooth'});
      }, 200)
    }
    ,
    search() {
      this.isSearch = false;
    }
  }
  ,
  methods: {
    FormatNumber(val) {
      return val !== "" && val !== null && val !== undefined ? numeral(val).format("0,00") : "";
    }
    ,
    handleSearch() {
      this.fetchAssociationByGeoOrName();
      this.isSearch = true;
      setTimeout(function () {
        // window.scrollTo(0, document.body.scrollHeight);
        document.getElementById("resultsearch").scrollIntoView({behavior: 'smooth'});
      }, 200)
    }
    ,
    gotoAssociation(id) {
      let vm = this;
      vm.$router.push({
        name: "Association", params: {id: id}
      })
    }
    ,
    fetchProvince() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getProvinceAss;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          language: vm.$i18n.locale
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.provinceOptionList = res.data.data || [];
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
    ,
    fetchDistrict(provinceId) {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getDistrictAss;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          provinceId: provinceId,
          language: vm.$i18n.locale
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.districtOptionList = res.data.data || [];
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
    ,
    fetchCommune(districtId) {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getCommuneAss;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          districtId: districtId,
          language: vm.$i18n.locale
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.communeOptionList = res.data.data || [];
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
    ,
    fetchVillage(communeId) {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getVillageAss;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          communeId: communeId,
          language: vm.$i18n.locale
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.villageOptionList = res.data.data || [];
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
    ,
    fetchAssociationByGeoOrName() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getAssociationByGeoOrName;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          provinceId: vm.params.provinceId || "",
          districtId: vm.params.districtId || "",
          communeId: vm.params.communeId || "",
          villageId: vm.params.villageId || "",
          search: vm.search || "",
          language: vm.$i18n.locale
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.result = res.data.data && res.data.data.content || [];
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
    ,
    fetchImageByPrepare() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.imageByType;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          type: "Prepare By",
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.prepareByList = res.data.data && res.data.data.content || [];
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
    ,
    fetchImageBySupport() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.imageByType;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          type: "Support By",
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.supportByList = res.data.data && res.data.data.content || [];
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
    ,
    fetchHomePageDashBoard() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getHomePageDashboard;
      vm.chartData.label = [];
      vm.chartData.labels = [];
      vm.chartDataService.labels=[];
      vm.chartData.datasets[0].data = [];
      vm.chartDataActionList = [];
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          language: vm.$i18n.locale
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.result = res.data.data && res.data.data.content || [];
                vm.totalAssociation = res.data.data.totalAssociation;
                let totalLessThan60 = res.data.data && res.data.data.totalDoc[0].totalLessThan60 || 0;
                let totalOver60 = res.data.data && res.data.data.totalDoc[0].totalOver60 || 0;
                let femaleLessThan60 = res.data.data && res.data.data.totalDoc[0].femaleLessThan60 || 0;
                let femaleOver60 = res.data.data && res.data.data.totalDoc[0].femaleOver60 || 0;
                vm.totalMale = totalLessThan60 + totalOver60 - (femaleLessThan60 + femaleOver60);
                vm.totalFeMale = femaleLessThan60 + femaleOver60;
                vm.femaleDisability = res.data.data && res.data.data.totalDoc[0].femaleDisability || 0;

                let totalDisability = res.data.data && res.data.data.totalDoc[0].totalDisability || 0;
                vm.maleDisability = totalDisability - vm.femaleDisability;

                let incPro = 1;
                res.data.data.associationByProvince.forEach((d) => {
                  if (incPro <= 5) {
                    if (vm.$i18n.locale === 'en') {
                      vm.chartData.labels.push(d.provinceDoc && d.provinceDoc.latinName || d.provinceDoc.name || "");

                    } else {
                      vm.chartData.labels.push(d.provinceDoc && d.provinceDoc.name || "");

                    }

                    vm.chartData.datasets[0].data.push(d.total || 0);
                    incPro++;
                  }
                })
                res.data.data.serviceTop.forEach((d) => {
                  if (vm.$i18n.locale === 'en') {
                    vm.chartDataService.labels.push(d.serviceDoc && d.serviceDoc.latinName || d.serviceDoc.name || "");

                  } else {
                    vm.chartDataService.labels.push(d.serviceDoc && d.serviceDoc.name || "");

                  }
                  vm.chartDataService.datasets[0].data.push(d.total || 0);
                })
                vm.chartDataService.labels.push("");
                vm.chartDataService.labels.push("");
                vm.chartDataService.datasets[0].data.push(0);
                vm.chartDataService.datasets[0].data.push(0);
                res.data.data.serviceList.forEach((sDoc) => {
                  if (sDoc._id && sDoc._id.serviceId && sDoc._id.serviceId !== null) {
                    /* let chartDataDoc = {};
                     chartDataDoc.labels = [];
                     chartDataDoc.datasets = [];


                     let dSetDoc = {};
                     dSetDoc.data = [];
                     dSetDoc.backgroundColor = [];
                     sDoc.data.forEach((labelAndValueDoc) => {
                       chartDataDoc.labels.push(labelAndValueDoc._id.value);
                       dSetDoc.backgroundColor.push(labelAndValueDoc._id.value === "ដំណើរការល្អធម្មតា" ? '#88ef6f' : labelAndValueDoc._id.value === "ដំណើរការមិនសូវល្អ" ? '#f68d78' : '#00D8FF');
                       dSetDoc.data.push(labelAndValueDoc.total);
                     })
                     chartDataDoc.serviceName = sDoc && sDoc.serviceDoc && sDoc.serviceDoc.name || "";
                     chartDataDoc.datasets.push(dSetDoc);
                     vm.chartDataActionList.push(chartDataDoc);*/

                    let chartDataDoc = {};
                    chartDataDoc.total = sDoc.total;
                    if(vm.$i18n.locale==='en'){
                      chartDataDoc.serviceName = sDoc && sDoc.serviceDoc && sDoc.serviceDoc.latinName || sDoc.serviceDoc.name || "";

                    }else{
                      chartDataDoc.serviceName = sDoc && sDoc.serviceDoc && sDoc.serviceDoc.name || "";

                    }
                    chartDataDoc.url = sDoc && sDoc.serviceDoc && sDoc.serviceDoc.url || "";
                    vm.chartDataActionList.push(chartDataDoc);
                  }
                })

              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
    ,
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight};
    }
    ,

  }
  ,
  created() {
    let vm = this;
    vm.fetchProvince();
    vm.fetchHomePageDashBoard();
    vm.fetchImageByPrepare();
    vm.fetchImageBySupport();
  }
  ,
}
;
</script>


<style scoped>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 700px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 700px;
}

.gmap_iframe {
  height: 700px !important;
}

.member_counter {
  padding: 73px 0px 73px;
  background-image: -webkit-linear-gradient(0deg, lightblue 0%, #244a93 100%);
}

.changeColor:hover {
  color: darkgoldenrod;
  opacity: 1;
  cursor: pointer;
  elevation: 20deg;
}

.ten_news_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0px;
  padding: 10px 0;
  border-bottom: 1px solid #f4f4f4;
}

.program :hover {
  elevation: 30deg;
  color: darkgoldenrod;
}

.imgZoom :hover {
  transition: transform .2s;
  transform: scale(1.2);
}

.imgZoomActive {
  transition: transform .2s;
  transform: scale(1.2);
}

.imgZoom1 :hover {
  transition: transform .20s;
  transform: scale(1.05);
}

.imgUp :hover {
  transition: transform .2s;
  margin-top: -10px;
}

.centered {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centeredTitle {
  position: absolute;
  top: 107%;
  left: 50%;
  transform: translate(-50%, 0%);

}

.changeOnHover :hover {
  background: lightgreen;
  color: white;
}

.img-circle {
  border-radius: 50%;
}

.row {
  margin: 0px !important;
}


ul.custom-module {
}

ul.custom-module li {
  maring: 5px;
  padding: 50px;
  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid green;
  /*background: rgba(255, 255, 255, 0.5);*/
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;

}

ul.custom-module li:hover {
  background: rgba(0, 59, 108, 0.8);
  border-radius: 20px;
  transform: scale(1);
  border: none;
  color: white;

}

ul.custom-module li.active-module {
  background: rgba(0, 59, 108, 0.8);
  border-radius: 20px;
  transform: scale(1);
  border: none;

}

i.v-icon:hover {
  color: white;
}

.mgTop {
  padding-top: 20px;
}

ul.custom-module1 li {
  maring: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  height: 180px !important;
  width: 370px !important;
  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid rgba(237, 22, 79, 255);
  /*background: rgba(255, 255, 255, 0.5);*/
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;

}
</style>
